import router from '@/router'
import {buildStringTemplate, deepClone, removeDuplicatesFromArray} from "@/utils"
import apiClient from "@/services/apiClient"
import axios from "axios";
import {routeToClosedDrawer} from "@/router/routeHelper";
import {modalState} from "@/components/common/types/modal";

interface GraphNodeConfiguration {
    address: String,
    maxHops: Number,
    limit: Number,
    asAddress: Number,
    includeClusters: Number,
    directions: String,
    paths: String,
    graphId: String,
    graphConfigId: String,
}

const state = {
    // Used for page of graphs
    graphs: [],
    graphsLoading: false,

    // Used for single graph (graph detail)
    graphData: null,
    selectedGraphConfiguration: null,
    graphLoading: true,
    bottomDrawer: false,
    graphsCancelToken: axios.CancelToken.source(),
    graphsModalState: modalState.Initial,
    newNodeModalState: modalState.Initial,
}

const getters = {
    graphs: (state) => state.graphs,
    graphsLoading: (state) => state.graphsLoading,
    graphData: (state) => state.graphData,
    selectedGraphConfiguration: (state) => state.selectedGraphConfiguration,
    graphLoading: (state) => state.graphLoading,
    graphBottomDrawer: (state) => state.bottomDrawer,
    graphsModalState: (state) => state.graphsModalState,
    newNodeModalState: (state) => state.newNodeModalState,
}

const mutations = {
    GRAPHS_PAGE_LOADED(state, data) {
        state.graphs = data
    },
    GRAPHS_START_LOADING(state) {
        state.graphsCancelToken.cancel()
        state.graphsCancelToken = axios.CancelToken.source()
        state.graphsLoading = true
    },
    GRAPHS_FINISH_LOADING(state) {
        state.graphsLoading = false
    },
    START_GRAPH_PAGE_LOADING(state) {
        state.graphLoading = true
    },
    FINISH_GRAPH_PAGE_LOADING(state) {
        state.graphLoading = false
    },
    GRAPH_LOADED(state, {data, graphConfigId}) {
        state.graphData = data
        let graphConfiguration = state.graphData.configs.find(config => config.id === graphConfigId)
        //if config not exist load last existing configuration
        if (!graphConfiguration) {
            graphConfiguration = state.graphData.configs[0]
        }
        state.selectedGraphConfiguration = graphConfiguration
    },
    GRAPH_CLOSE_BOTTOM_DRAWER(state, {sides}) {
        //TODO move this logic to Views, now is not possible because o nested views
        routeToClosedDrawer(router, {sides, graphId: this.getters.graphData?.id})
        state.bottomDrawer = false
    },
    GRAPH_REMOVE_NODE(state, data) {
        //observable caused problem because of "inner upload". we must relaod whole object 
        let newSelectedGraphConfiguration = deepClone(state.selectedGraphConfiguration)
        const itemToRemove = newSelectedGraphConfiguration.json_config.graph.elements.nodes.find(i => i.data.hash === data.hash)
        newSelectedGraphConfiguration.json_config.graph.elements.nodes = newSelectedGraphConfiguration.json_config.graph.elements.nodes.filter(i => i.data.id !== itemToRemove.data.id)
        newSelectedGraphConfiguration.json_config.graph.elements.edges = newSelectedGraphConfiguration.json_config.graph.elements.edges.filter(i => i.data.target !== itemToRemove.data.id)

        state.selectedGraphConfiguration = newSelectedGraphConfiguration
        //closed bottom drawer only if was opened, GRAPH_CLOSE_BOTTOM_DRAWER make redirect
        //todo terrible hack, remove this redirect stuff to component!
        this.commit('GRAPH_CLOSE_BOTTOM_DRAWER', {})
    },
    GRAPH_LOAD_CONFIG(state, config) {
        state.selectedGraphConfiguration = config
    },
    APPEND_GRAPH_DATA_TO_ACTUAL_GRAPH_CONFIG(state, {data}) {
        const allEdges = state.selectedGraphConfiguration.json_config.graph.elements.edges.concat(data.edges);
        const allNodes = state.selectedGraphConfiguration.json_config.graph.elements.nodes.concat(data.nodes);
        state.selectedGraphConfiguration.json_config.graph.elements.edges = removeDuplicatesFromArray(allEdges, i => i.data.id)
        state.selectedGraphConfiguration.json_config.graph.elements.nodes = removeDuplicatesFromArray(allNodes, i => i.data.id)
    },
    GRAPH_CLEAN_UP(state) {
        state.graphData = null
        state.selectedGraphConfiguration = null
    },
    GRAPHS_MODAL_SET_STATE(state, data) {
        state.graphsModalState = data
    },
    GRAPH_NEW_NODE_MODAL_SET_STATE(state, data) {
        state.newNodeModalState = data
    },
}

const actions = {
    async loadGraphs({commit, dispatch}, {pagination}) {
        try {
            commit('GRAPHS_START_LOADING')
            const result = await apiClient.GET("graphs", {
                params: pagination,
                cancelToken: state.graphsCancelToken.token
            })
            commit('GRAPHS_PAGE_LOADED', result)
            commit('GRAPHS_FINISH_LOADING')
            return result
        } catch (error) {
            if (axios.isCancel(error)) {
                //request canceled, do nothing
            } else {
                dispatch("error", error.userFriendlyMessage)
                commit('GRAPHS_FINISH_LOADING')
            }
        }
        return false
    },
    async loadGraph({commit, dispatch}, {graphId, graphConfigId, transactionNetworkIdToAppend, currency}) {
        commit('START_GRAPH_PAGE_LOADING')
        try {
            const result = await apiClient.GET(`graphs/${graphId}`)
            if (transactionNetworkIdToAppend) {
                dispatch('getTransactionGraphDataAndJoinWithExistingGraphData', {transactionNetworkIdToAppend: transactionNetworkIdToAppend, currency: currency, data: result, graphConfigId: graphConfigId})
            } else {
                commit('GRAPH_LOADED', {data: result, graphConfigId})
                commit('FINISH_GRAPH_PAGE_LOADING')
            }
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
            commit('FINISH_GRAPH_PAGE_LOADING')
        }
    },

    async addNewGraphNode({commit, dispatch}, {graphNodeConfiguration}: {
        graphNodeConfiguration: GraphNodeConfiguration
    }) {
        commit('START_GRAPH_PAGE_LOADING')
        commit("GRAPH_NEW_NODE_MODAL_SET_STATE", modalState.Pending)
        const requestUrl = buildStringTemplate({
            template: "graphs/${graphId}/config/${graphConfigId}/node/${nodeId}",
            values: {
                graphId: graphNodeConfiguration.graphId,
                graphConfigId: graphNodeConfiguration.graphConfigId,
                nodeId: graphNodeConfiguration.address
            }
        })
        const {graphId, graphConfigId, address, ...query} = graphNodeConfiguration
        try {
            const response = await apiClient.GET(requestUrl, {params: query})
            commit('GRAPH_LOADED', {data: response})
            commit("GRAPH_NEW_NODE_MODAL_SET_STATE", modalState.Success)
        } catch (error) {
            dispatch("error", error)
            commit("GRAPH_NEW_NODE_MODAL_SET_STATE", modalState.Error)
        } finally {
            commit('FINISH_GRAPH_PAGE_LOADING')
        }

    },

    async createGraphConfiguration({commit, getters, dispatch}, {graphId, graphJson, description}) {
        commit('START_GRAPH_PAGE_LOADING')
        try {
            const response = await apiClient.POST(`graphs/${graphId}/config`, {
                description: description,
                json_config: {
                    graph: graphJson,
                    entities: getters.selectedGraphConfiguration.json_config.entities
                }
            })
            commit('GRAPH_LOADED', {data: response})
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
        }
        commit('FINISH_GRAPH_PAGE_LOADING')
    },
    async updateGraphConfiguration({commit, dispatch}, {graphConfigurationDetail}) {
        try {
            commit('START_GRAPH_PAGE_LOADING')
            const response = await apiClient.PUT(`graphs/${graphConfigurationDetail.belongs_to}/config/${graphConfigurationDetail.id}`, graphConfigurationDetail)
            dispatch("success", response.message)
        } catch (error) {
            dispatch("error", error.message)
        } finally {
            commit('FINISH_GRAPH_PAGE_LOADING')
        }
    },
    async deleteGraphConfiguration({commit, dispatch}, {graphId, graphConfigId}) {
        try {
            commit('START_GRAPH_PAGE_LOADING')
            const response = await apiClient.DELETE(`graphs/${graphId}/config/${graphConfigId}`)
            dispatch("success", response.message)
        } catch (error) {
            dispatch("error", error.message)
        } finally {
            commit('FINISH_GRAPH_PAGE_LOADING')
        }
    },
    changeGraphConfig({commit, state}, {graphConfigId}) {
        const config = state.graphData.configs.find(config => config.id === graphConfigId)
        commit('GRAPH_LOAD_CONFIG', config)
    },
    graphCloseBottomDrawer({commit}, {sides = undefined}) {
        commit('GRAPH_CLOSE_BOTTOM_DRAWER', {sides})
    },
    removeGraphNode({commit}, data) {
        commit('GRAPH_REMOVE_NODE', data)
    },
    async appendTransactionNetworkToExistingGraph({commit, dispatch}, {transactionNetworkIdToAppend, currency, sides}) {
        const requestAddress = `${currency}/cryptotransaction/${transactionNetworkIdToAppend}/graph`
        commit('START_GRAPH_PAGE_LOADING');
        try {
            const result = await apiClient.GET(requestAddress, {params: {sides: sides}})
            commit('APPEND_GRAPH_DATA_TO_ACTUAL_GRAPH_CONFIG', {data: result.graph_data})
        } catch (error) {
            dispatch("error", error.message)
        } finally {
            commit('FINISH_GRAPH_PAGE_LOADING')
        }
    },
    async getTransactionGraphDataAndJoinWithExistingGraphData({commit, dispatch}, {transactionNetworkIdToAppend, currency, data, graphConfigId}) {
        const requestAddress = `${currency}/cryptotransaction/${transactionNetworkIdToAppend}/graph`
        try {
            const result = await apiClient.GET(requestAddress, {params: {sides: 'both'}})

            const selectedConfigOrLast = graphConfigId ? graphConfigId : 0

            const joinedElements = {
                edges: removeDuplicatesFromArray([...data.configs[selectedConfigOrLast].json_config.graph.elements.edges, ...result.graph_data.edges], i => i.data.id),
                nodes: removeDuplicatesFromArray([...data.configs[selectedConfigOrLast].json_config.graph.elements.nodes, ...result.graph_data.nodes], i => i.data.id)
            }

            //override original elements object
            data.configs[selectedConfigOrLast].json_config.graph.elements = joinedElements

            commit('GRAPH_LOADED', {data: data, graphConfigId})
        } catch (error) {
            dispatch("error", error.message)
        } finally {
            commit('FINISH_GRAPH_PAGE_LOADING')
        }
    },
    async deleteGraph({dispatch}, {graphId}) {
        try {
            const response = await apiClient.DELETE(`graphs/${graphId}`)
            if (response) {
                dispatch("success", response.message);
            }
        } catch (error) {
            dispatch("error", error.message)
        }
    },
    async editGraph({dispatch, commit}, {graph}) {
        commit('GRAPHS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.PUT(`graphs/${graph.id}`, graph)
            dispatch("success", response.message);
            commit('GRAPHS_MODAL_SET_STATE', modalState.Success)
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
            commit('GRAPHS_MODAL_SET_STATE', modalState.Error)
        }
    },
    async createGraph({dispatch, commit}, {graph}) {
        commit('GRAPHS_MODAL_SET_STATE', modalState.Pending)
        try {
            const response = await apiClient.POST(`graphs`, graph, undefined, true)
            commit('GRAPHS_MODAL_SET_STATE', modalState.Success)
            return Number(response.headers.location.split("/").pop())
        } catch (error) {
            dispatch("error", error.userFriendlyMessage)
            commit('GRAPHS_MODAL_SET_STATE', modalState.Error)
        }
    },
    async cleanUpGraph({commit}) {
        commit('GRAPH_CLEAN_UP')
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
